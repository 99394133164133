var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"addAndEditBank"},[_c('van-sticky',[_c('van-nav-bar',{attrs:{"title":"银行卡管理","left-arrow":""},on:{"click-left":_vm.onClickLeft}})],1),_c('div',[_c('div',{staticClass:"bank-title"},[_c('div',[_vm._v(_vm._s(_vm.$route.query.title)+"银行卡")]),_c('p',[_vm._v("只可"+_vm._s(_vm.$route.query.title)+"与实名认证同一个人的银行卡")])]),_c('van-form',{on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"readonly":"","clickable":"","name":"bank","label":"","right-icon":"arrow-down","value":_vm.form.bankName,"placeholder":"请选择开户行","rules":[{ required: true, message: '请选择开户行' }]},on:{"click":function($event){_vm.showPicker = true}}}),_c('van-popup',{attrs:{"round":"","position":"bottom"},model:{value:(_vm.showPicker),callback:function ($$v) {_vm.showPicker=$$v},expression:"showPicker"}},[_c('van-picker',{attrs:{"show-toolbar":"","columns":_vm.bankList,"value-key":"label"},on:{"cancel":function($event){_vm.showPicker = false},"confirm":function (ref) {
              var label = ref.label;
              var value = ref.value;

              this$1.form.bank = value;
              this$1.form.bankName = label;
              this$1.showPicker = false;
            }}})],1),_c('van-field',{attrs:{"name":"bankAccountNo","label":"","placeholder":"请输入银行卡号","rules":[
        { required: true, message: '请输入银行卡号' },
        {
          required: true,
          message: '银行卡号格式限制为10位至30位数字',
          validator: function validator(val) {
            return /^[1-9]\d{9,29}$/.test(val);
          },
        } ]},model:{value:(_vm.form.bankAccountNo),callback:function ($$v) {_vm.$set(_vm.form, "bankAccountNo", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.bankAccountNo"}}),_c('van-field',{attrs:{"name":"bankMobile","label":"","placeholder":"银行预留手机号","rules":[
          { required: true, message: '请输入银行预留手机号' },
          {
            pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
            message: '请输入正确的银行预留手机号',
          } ]},model:{value:(_vm.form.bankMobile),callback:function ($$v) {_vm.$set(_vm.form, "bankMobile", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.bankMobile"}}),_c('div',{staticClass:"notes"},[_c('div',[_vm._v("备注: ")]),(_vm.$route.query.title==='添加')?_c('p',[_c('span',[_vm._v("如果不记得银行预留手机号,可以前往说及银行中查看;")])]):_vm._e(),(_vm.$route.query.title==='更换')?_c('p',[_c('span',[_vm._v("如果不记得银行预留手机号,可以前往说及银行中查看;")]),_c('br'),_c('span',[_vm._v("更换成功后,原绑定的银行卡将解除绑定;")]),_c('br'),_c('span',[_vm._v("若更换失败,需重新绑定银行卡;")])]):_vm._e()]),_c('div',{staticClass:"save"},[_c('van-button',{attrs:{"round":"","block":"","type":"info","native-type":"onSubmit","loading":_vm.saving}},[_vm._v("提交")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }