<template>
  <div class="addAndEditBank">
    <van-sticky>
      <van-nav-bar title="银行卡管理" left-arrow @click-left="onClickLeft">
      </van-nav-bar>
    </van-sticky>
    <div>
      <div class="bank-title">
        <div>{{$route.query.title}}银行卡</div>
        <p>只可{{$route.query.title}}与实名认证同一个人的银行卡</p>
      </div>
      <van-form @submit="onSubmit">
        <!-- <van-field
          v-model="username"
          name="开户行"
          label=""
          placeholder="请选择开户行"
          :rules="[{ required: true, message: '请选择开户行' }]"
        /> -->
        <van-field
          readonly
          clickable
          name="bank"
          label=""
          right-icon="arrow-down"
          :value="form.bankName"
          placeholder="请选择开户行"
          :rules="[{ required: true, message: '请选择开户行' }]"
          @click="showPicker = true"
        />
        <van-popup v-model="showPicker" round position="bottom">
          <van-picker
            show-toolbar
            :columns="bankList"
            value-key="label"
            @cancel="showPicker = false"
            @confirm="
              ({ label, value }) => {
                this.form.bank = value;
                this.form.bankName = label;
                this.showPicker = false;
              }
            "
          />
        </van-popup>
        <!-- <van-field
          v-model="password"
          type="password"
          name="银行卡号"
          label=""
          placeholder="请输入银行卡号"
          :rules="[{ required: true, message: '请输入银行卡号' }]"
        /> -->
        <van-field
          v-model.trim="form.bankAccountNo"
          name="bankAccountNo"
          label=""
          placeholder="请输入银行卡号"
          :rules="[
          { required: true, message: '请输入银行卡号' },
          {
            required: true,
            message: '银行卡号格式限制为10位至30位数字',
            validator(val) {
              return /^[1-9]\d{9,29}$/.test(val);
            },
          },]"
        >
        </van-field>
        <!-- <van-field
          v-model="password"
          type="password"
          name="银行预留手机号"
          label=""
          placeholder="银行预留手机号"
          :rules="[{ required: true, message: '请输入银行预留手机号' }]"
        /> -->
        <van-field
          v-model.trim="form.bankMobile"
          name="bankMobile"
          label=""
          placeholder="银行预留手机号"
          :rules="[
            { required: true, message: '请输入银行预留手机号' },
            {
              pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
              message: '请输入正确的银行预留手机号',
            },
          ]"
        />
        <div class="notes">
         <div>备注: </div>
           <p v-if="$route.query.title==='添加'">
             <span>如果不记得银行预留手机号,可以前往说及银行中查看;</span>
         </p>
         <p v-if="$route.query.title==='更换'">
             <span>如果不记得银行预留手机号,可以前往说及银行中查看;</span>
             <br>
             <span>更换成功后,原绑定的银行卡将解除绑定;</span>
              <br>
             <span>若更换失败,需重新绑定银行卡;</span>
         </p>
        </div>
        <div class="save">
          <van-button round block type="info" native-type="onSubmit" :loading="saving"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";
import { getUserInfo, saveUser, saveUserBankCard } from '@/api/user';
import { getBankList } from "@/api/user";
import { Toast } from "vant";
export default {
  name: "addAndEditBank",
  data() {
    return {
      userInfo: {},
      initInfo: null,
      bankList: [],
      showPicker: false,

      inputType: "password",
      form: {
        bankMobile: "", // 手机号码
        bank: "", // 开户行
        bankName: "",
        // subBank: '', // 开户支行
        bankProvince: "", // 银行所在省
        bankProvinceName: "",
        bankCity: "", // 银行所在市
        bankCityName: "",
        bankAccountNo: "", // 银行卡号
        idNum: "", // 身份证号
      },
      saving: false,
      reSubmitCountDownTime: 0,
    };
  },
  async created() {
    let countDown =
      window.localStorage.getItem("userInfoReSubmitCountDown") || 0;
    if (countDown && Date.now() < countDown) {
      this.reSubmitCountDownTime = +countDown - Date.now();
    }

    if (this.$route.query.title==='更换') {
      await getUserInfo().then((res) => {
        this.userInfo = res || {};
        this.form = [
          {},
          "bankMobile",
          "bank",
          "bankProvince",
          "bankProvinceName",
          "bankCity",
          "bankCityName",
          "bankAccountNo",
          "idNum",
        ].reduce((obj, key) => {
          obj[key] = this.userInfo[key] || "";
          return obj;
        });
        this.initInfo = { ...this.form };
      });
    }

    getBankList().then((res) => {
      this.bankList = res || [];
      let bankName = "";
      for (let bank of this.bankList) {
        if (bank.value === this.userInfo.bank) {
          bankName = bank.label;
          break;
        }
      }
      this.form.bankName = bankName;
    });
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    onSubmit() {
      this.saving = true;
      Toast.loading({})
      saveUserBankCard({ ...this.form }, { headers: { getAllResponse: true } }).then(
        (res) => {
          console.log("保存", res);
          this.saving = false;
          Toast.clear()

          if (res.data.code == 0) {
            window.localStorage.setItem(
                "userInfoReSubmitCountDown",
                Date.now() + 30 * 1000
            );
            this.reSubmitCountDownTime = 30 * 1000;
            Toast("保存成功");
            this.$router.back();
            return;
          }else{
            Toast(res.data.msg);
          }

          Dialog.alert({
            title: "请签署协议",
            // message: res.data.contractList[0].contractTitle,
            message: res.data.contractList
              .map((it) => it.contractTitle)
              .join("\n"),
          }).then(() => {
            // on close
            this.iframeUrl = res.data.contractList[0].shortUrl;
            //  console.log(' this.iframeUrl', this.iframeUrl);
            this.$router.push({
              path: "/userInfoUrl",
              query: { iframeUrl: this.iframeUrl },
            });
            // userInfoUrl
          });
        }
      );
    },
  },
};
</script>

<style scoped lang='less'>
.addAndEditBank {
  background-color: #fff;
  height: 100vh;
  .bank-title {
    color: #212121;
    margin: 28px 17px 20px;
    font-size: 16px;
    > div {
      font-weight: 700;
    }
    > p {
      color: #8bc3ff;
      margin-top: 8px;
      font-size: 12px;
    }
  }
  .notes {
      display: flex;
    color: #aea4a8;
    margin: 20px 16px 0;
    font-size: 12px;
    font-weight: 700;
    >div{
        margin-right: 10px;
         line-height: 22px;
    }
    >p{
            line-height: 22px;
    }
  }
  .save {
    margin: 16px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 92%;
    .van-button--info{
      background-color: #5885ee;
      border: none;
    }
  }
}
::v-deep .van-nav-bar {
  background-color: #f8f8f8;
  .van-icon {
    color: #212121;
    font-size: 18px;
  }
}
</style>
